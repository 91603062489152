<script>
import axios from "axios";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      doctor: [],
      e: [],
    };
  },
  methods: {
    async getDoctor() {
      const res = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/" +
          this.$route.params.id
      );
      this.doctor = res.data;
      console.log(this.doctor);
      this.e = this.doctor.created_at;
      console.log(this.e);
    },
  },
  created() {
    this.getDoctor();
  },
};
</script>

<template>
  <Navbar />
  <!-- <div class="container-fluid"> -->
  <div class="w-100 user-chat-default">
    <div class="p-3 user-chat-topbar">
      <b-row class="align-items-center">
        <b-col sm="4" cols="8">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1 n">
              <div class="d-flex align-items-center">
                <div
                  class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0"
                >
                  <img
                    :src="
                      profile
                        ? profile
                        : require('@/assets/images/users/user-dummy-img.jpg')
                    "
                    class="rounded-circle avatar-xs"
                    alt="profile"
                  />
                  <!-- <span class="user-status"></span> -->
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="text-truncate mb-0 fs-16">
                    <b-link
                      class="text-reset username"
                      data-bs-toggle="offcanvas"
                      href="#userProfileCanvasExample"
                      aria-controls="userProfileCanvasExample"
                      >{{ doctor.name }}
                    </b-link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="position-relative" id="users-chat">
      <div
        class="chat-conversation p-3 p-lg-4"
        id="chat-conversation"
        data-simplebar
        ref="current"
      >
        <ul class="list-unstyled chat-conversation-list">
          <li class="chat-list right">
            <div class="conversation-list">
              <div class="chat-avatar">
                <img
                  :src="
                    profile
                      ? profile
                      : require('@/assets/images/users/user-dummy-img.jpg')
                  "
                  alt=""
                />
              </div>
              <div class="user-chat-content">
                <div class="ctext-wrap">
                  <div class="ctext-wrap-content">
                    <p class="mb-0 ctext-content">Hello from patient</p>
                  </div>

                  <div class="conversation-name">
                    <!-- <small class="text-muted time">{{ e.date}}</small> -->
                    <span class="text-success check-message-icon"
                      ><i class="ri-check-double-line align-bottom"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="chat-list">
            <div class="conversation-list">
              <div class="chat-avatar">
                <img
                  :src="
                    profile
                      ? profile
                      : require('@/assets/images/users/user-dummy-img.jpg')
                  "
                  alt=""
                />
              </div>
              <div class="user-chat-content">
                <div class="ctext-wrap">
                  <div class="ctext-wrap-content">
                    <p class="mb-0 ctext-content">Hi from {{ doctor.name }}</p>
                  </div>

                  <div class="conversation-name">
                    <!-- <small class="text-muted time">{{ e.date }}</small> -->
                    <span class="text-success check-message-icon"
                      ><i class="ri-check-double-line align-bottom"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-input-section p-3 p-lg-4">
      <form @submit.prevent="formSubmit">
        <b-row class="g-0 align-items-center">
          <b-col>
            <div class="chat-input-feedback">Please Enter a Message</div>

            <input
              type="text"
              class="form-control chat-input bg-light border-light"
              placeholder="Enter Message..."
            />
          </b-col>
          <b-col cols="auto">
            <div class="chat-input-links ms-2">
              <div class="links-list-item">
                <b-button variant="success" type="submit" class="chat-send">
                  <i class="ri-send-plane-2-fill align-bottom"></i>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </form>
    </div>
  </div>
  <!-- </div> -->
  <Footer />
</template>
<style scoped>
.user-chat-default {
  background-color: #f3f3f9;
}
.user-chat-topbar {
  position: relative;
}
.chat-conversation {
  overflow-y: auto;
}
.chat-conversation .conversation-list .ctext-wrap {
  gap: 10px;
}
</style>
